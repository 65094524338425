<template>
    <div class="clientcabin clientcabin-premium">
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <p class="headline text-center my-4">
                        We Establish Our Clients As Leading Authorities To Boost
                        Their Rankings, Reputation, Traffic & Sales
                    </p>
                    <p class="text-h4 text-center pb-md-10">
                        By Getting Them Published On Major Business News Sites
                        Like Business Insider, Barchart, Benzinga & Other 7-8
                        Figure Traffic Sites
                    </p>
                    <p>
                        Have you ever wondered why some businesses are able to
                        charge several times more than their competition?
                    </p>
                    <p>
                        Or why some businesses are always busy or fully booked
                        up, no matter what the economy is doing?
                    </p>
                    <p>The answer is found in their AUTHORITY.</p>
                    <p>
                        They carry enough authority to be the #1 choice in their
                        market.
                    </p>
                    <p>
                        So as long as the market has demand, they will thrive
                        while lesser known, less trusted competitors will
                        suffer.
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <div class="clientcabin-darkblue">
            <v-container>
                <v-row justify="center">
                    <v-col
                        cols="12"
                        lg="9"
                        xl="7"
                        class="py-6 py-md-10 white--text text-subtitle-1"
                    >
                        <p class="subheadline text-center pb-6 my-4">
                            So How Do You Get Enough Authority In Your Market To
                            Become The #1 Choice?
                        </p>
                        <p>
                            Authority is gained primarily through social proof -
                            and the higher the authority of those talking about
                            you; the more authority gets passed on to you.
                        </p>
                        <p>
                            After helping many businesses effectively increase
                            their online visibility, we recognized the need to
                            establish well-deserved authority FAST.
                        </p>
                        <p>
                            That’s why we created a simple way for you to get
                            published on some of the most trafficked and
                            influential websites in the world.
                        </p>
                        <p>Here are some examples...</p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="9"
                    xl="7"
                    class="py-6 py-md-10 text-subtitle-1"
                >
                    <v-img
                        class="elevation-3 rounded-lg mb-8"
                        src="@/assets/img/clientcabin/premium/BusinessInsider.png"
                    />
                    <v-img
                        class="elevation-3 rounded-lg mb-8"
                        src="@/assets/img/clientcabin/premium/GlobeAndMail.png"
                    />
                    <v-img
                        class="elevation-3 rounded-lg mb-8"
                        src="@/assets/img/clientcabin/premium/Barchart.png"
                    />
                    <v-img
                        class="elevation-3 rounded-lg mb-8"
                        src="@/assets/img/clientcabin/premium/Medium.png"
                    />
                    <v-img
                        class="elevation-3 rounded-lg mb-8"
                        src="@/assets/img/clientcabin/premium/AssociatedPress.png"
                    />
                    <v-img
                        class="elevation-3 rounded-lg mb-8"
                        src="@/assets/img/clientcabin/premium/Benzinga.png"
                    />
                    <v-img
                        class="elevation-3 rounded-lg mb-8"
                        src="@/assets/img/clientcabin/premium/Newsmax.png"
                    />
                    <v-img
                        class="elevation-3 rounded-lg mb-8"
                        src="@/assets/img/clientcabin/premium/StreetInsider.png"
                    />
                    <p>
                        Once you understand the power of Instant Authority and
                        welcome it into your business, you will begin to
                        position yourself in your marketplace and the minds of
                        your prospects better than anyone else.
                    </p>
                    <p>
                        You will have been established as the #1 most trusted
                        advisor in your marketplace.
                    </p>
                    <p>
                        In other words, a more favorable choice than your
                        competitors; all else being equal (and sometimes, even
                        when it's not - most of our clients find they can charge
                        higher prices as a result).
                    </p>
                    <p>And the benefits don’t stop there because…</p>
                </v-col>
            </v-row>
        </v-container>
        <div class="clientcabin-darkblue white--text">
            <v-container>
                <v-row justify="center">
                    <v-col
                        cols="12"
                        lg="9"
                        xl="7"
                        class="py-6 py-md-10 text-subtitle-1"
                    >
                        <p class="subheadline text-center pb-6 my-4">
                            Google Trusts These Sites So Much Their Content Gets
                            Preferred Treatment
                        </p>
                        <p>
                            Google will give content published on these sites
                            more exposure than less famous websites.
                        </p>
                        <p>
                            This means with our service, you can start driving
                            more traffic and sales in just a few hours.
                        </p>
                        <p>But here’s where it gets REALLY exciting…</p>
                        <p>As Warren Buffett says…</p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                    <v-card
                        class="black--text pa-10 d-flex flex-column align-center mb-10 mx-md-16"
                    >
                        <p class="text-h6 text-center">
                            “It takes 20 years to build a reputation and 5
                            minutes to ruin it. If you think about that, you’ll
                            do things differently.”
                        </p>
                        <v-img
                            class="my-4"
                            max-width="175"
                            src="@/assets/img/clientcabin/premium/warrenbuffett.png"
                        />
                        <p class="text-center text-h6 font-weight-bold">
                            Warren Buffett
                        </p>
                        <p>American Investor, Philanthropist & Billionaire</p>
                    </v-card>
                    <p class="text-subtitle-1">
                        You can enjoy the power of having multiple 8-figure
                        traffic sites and world-famous brands talking about you,
                        on this page, today.
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                        <p class="subheadline text-center">
                            Here's Where We Can Get You Published:
                        </p>
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="6"
                                    md="3"
                                    class="d-flex jsutify-center align-center"
                                >
                                    <v-img
                                        contain
                                        max-height="60"
                                        src="@/assets/img/clientcabin/premium/logos/bi.png"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                    md="3"
                                    class="d-flex jsutify-center align-center"
                                >
                                    <v-img
                                        contain
                                        max-height="175"
                                        src="@/assets/img/clientcabin/premium/logos/barchart.png"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                    md="3"
                                    class="d-flex jsutify-center align-center"
                                >
                                    <v-img
                                        contain
                                        max-height="175"
                                        src="@/assets/img/clientcabin/premium/logos/tgnm.png"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                    md="3"
                                    class="d-flex jsutify-center align-center"
                                >
                                    <v-img
                                        contain
                                        max-height="175"
                                        src="@/assets/img/clientcabin/premium/logos/medium.png"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                    md="3"
                                    class="d-flex jsutify-center align-center"
                                >
                                    <v-img
                                        contain
                                        max-height="110"
                                        src="@/assets/img/clientcabin/premium/logos/ap.png"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                    md="3"
                                    class="d-flex jsutify-center align-center"
                                >
                                    <v-img
                                        contain
                                        max-height="175"
                                        src="@/assets/img/clientcabin/premium/logos/benzinga.png"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                    md="3"
                                    class="d-flex jsutify-center align-center"
                                >
                                    <v-img
                                        contain
                                        max-height="175"
                                        src="@/assets/img/clientcabin/premium/logos/newsmax.png"
                                    />
                                </v-col>
                                <v-col
                                    cols="6"
                                    md="3"
                                    class="d-flex jsutify-center align-center"
                                >
                                    <v-img
                                        contain
                                        max-height="175"
                                        src="@/assets/img/clientcabin/premium/logos/si.png"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                        <p class="text-center">And more sites coming soon!</p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                    <p class="text-center text-subtitle-1">
                        Once Payment Is Complete We'll Gather Some Information
                        About Your Business & Get To Work Making You The #1
                        Choice In Your Market.
                    </p>
                    <p class="text-center text-h5">Select Your Option Below</p>
                    <v-container class="clientcabin-pricing">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-card elevation="10" class="rounded-lg">
                                    <v-img
                                        class="black--text align-center grey lighten-4"
                                        min-height="160px"
                                    >
                                        <div
                                            class="text-center option-title pa-4 mt-4"
                                        >
                                            Option 1
                                            <span class="option-plan">
                                                Plan 1
                                            </span>
                                            <br />
                                            <b>Single Purchase @</b>
                                        </div>
                                    </v-img>
                                    <div class="price text-center">
                                        {{
                                            reseller_client_premium_amp_credit_price
                                        }}
                                    </div>
                                    <v-card-text class="text-center">
                                        <v-btn
                                            x-large
                                            color="primary white--text"
                                            :block="
                                                $vuetify.breakpoint.smAndDown
                                            "
                                            class="px-10 py-8 text-h6 rounded-lg"
                                            @click="onOrder('single')"
                                        >
                                            Add to cart
                                        </v-btn>
                                    </v-card-text>
                                    <v-card-text class="pt-0 text-center">
                                        <v-icon class="mr-1">
                                            $vuetify.icons.secure
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.mastercard
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.amex
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.visa
                                        </v-icon>
                                        <v-icon large>
                                            $vuetify.icons.paypal
                                        </v-icon>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-card elevation="10" class="rounded-lg">
                                    <v-img
                                        class="black--text align-center grey lighten-4"
                                        min-height="160px"
                                    >
                                        <div
                                            class="text-center mt-n5 primary white--text text-button"
                                        >
                                            Most Popular
                                        </div>
                                        <div
                                            class="text-center option-title pa-4"
                                        >
                                            Option 2
                                            <span class="option-plan">
                                                Plan 2
                                            </span>
                                            <br />
                                            <b>Monthly Purchase @</b>
                                        </div>
                                    </v-img>
                                    <div class="price text-center">
                                        {{
                                            reseller_client_recurring_premium_amp_credit_price
                                        }}
                                    </div>
                                    <v-card-text class="text-center">
                                        <v-btn
                                            x-large
                                            color="primary white--text"
                                            :block="
                                                $vuetify.breakpoint.smAndDown
                                            "
                                            class="px-10 py-8 text-h6 rounded-lg"
                                            @click="onOrder('recurring')"
                                        >
                                            Add to cart
                                        </v-btn>
                                    </v-card-text>
                                    <v-card-text class="pt-0 text-center">
                                        <v-icon class="mr-1">
                                            $vuetify.icons.secure
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.mastercard
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.amex
                                        </v-icon>
                                        <v-icon large class="mr-1">
                                            $vuetify.icons.visa
                                        </v-icon>
                                        <v-icon large>
                                            $vuetify.icons.paypal
                                        </v-icon>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class Premium extends Vue {
    endpoint = '/premium';

    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get reseller_client_premium_amp_credit_price() {
        return this.format(
            this.options?.reseller_client_premium_amp_credit_price
        );
    }

    get reseller_client_recurring_premium_amp_credit_price() {
        return `${this.format(
            this.options?.reseller_client_recurring_premium_amp_credit_price
        )}/mo`;
    }

    format(price?: number) {
        if (price) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.options?.currency || 'USD',
                maximumFractionDigits: 0
            }).format(price);
        }

        return price;
    }

    onOrder(product: 'single' | 'recurring') {
        this.$http
            .post(this.endpoint, {
                [product]: 1
            })
            .then(({ data }) => {
                if (data.data.success) {
                    this.onSuccess(data.data);
                }
            });
    }

    onSuccess({ redirect }: { redirect?: string }) {
        if (redirect) {
            // payment page
            window.location.href = redirect;
        }
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-premium::v-deep {
    background-color: $white;
    color: $black;

    .option-title {
        font-size: 1.25rem;
        line-height: 1.8em;
        text-transform: uppercase;

        .option-plan {
            border: 2px solid grey;
            padding: 4px 8px;
            margin-left: 8px;
            font-weight: 600;
        }
    }

    .price {
        font-weight: 700;
        font-size: 67px;
        letter-spacing: -1px;
        text-align: center;
        padding: 25px 0;
        color: $black;
    }
}
</style>
